/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */
import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

function SEO({ seoData, uri, extraMeta, dateGmt, modifiedGmt, useSocialImage }) {
  const { site, ogImage } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
          }
        }
        ogImage: file(relativePath: { eq: "social-share.png" }) {
          ...SocialImage
        }
      }
    `,
  );

  const {
    title,
    metaDesc,
    canonical,
    opengraphDescription,
    opengraphTitle,
    opengraphType,
    twitterDescription,
    twitterTitle,
    twitterImage,
  } = seoData;

  const siteUrl = site.siteMetadata.siteUrl;
  let finalCanonical = siteUrl + uri;
  if (canonical && uri !== "/") {
    finalCanonical = canonical.startsWith("http") ? canonical : siteUrl + canonical;
  }
  const socialImage = (useSocialImage)
    ? useSocialImage().social.imgData
    : ogImage.childImageSharp.fixed;

  const defaultSEO = {
    title: title || 'Vivid',
    description: metaDesc || site.siteMetadata.description,
    canonical: finalCanonical,
    opengraphDescription: '',
    opengraphSiteName: 'Vivid',
    opengraphType: 'article',
  };

  const articleMeta =
    opengraphType === "article" && dateGmt
      ? [
          {
            name: "article:published_time",
            content: dateGmt,
          },
          {
            name: "article:modified_time",
            content: modifiedGmt,
          },
          {
            name: "og:updated_time",
            content: modifiedGmt,
          },
        ]
      : [];

    const imageMeta = socialImage
      ? [
        {
          property: "og:image",
          content: siteUrl + socialImage.src,
        },
        {
          property: "og:image:width",
          content: socialImage.width,
        },
        {
          property: "og:image:height",
          content: socialImage.height,
        },
        {
          name: "twitter:image",
          content:
          siteUrl + (twitterImage?.localFile?.publicURL || socialImage.src),
        },
        {
          name: "twitter:image:width",
          content: twitterImage?.mediaDetails.width || socialImage.width,
        },
        {
          name: "twitter:image:height",
          content: twitterImage?.mediaDetails.height || socialImage.height,
        },
        {
          name: "twitter:image:alt",
          content: twitterImage?.altText || 'Vividsocal share image',
        },
      ]
    : [];

  return (
    <Helmet
      htmlAttributes={{lang: 'en'}}
      link={
        defaultSEO.canonical
          ? [
              {
                rel: "canonical",
                href: defaultSEO.canonical,
              },
            ]
          : []
      }
      title={defaultSEO.title}
      meta={[
        {
          name: `title`,
          content: defaultSEO.title,
        },
        {
          name: "google-site-verification",
          content: "N41HlOqnlgd2AyjlubZKx9YpXFYT5mOF0UzaiuAiHP4",
        },
        {
          name: "yandex-verification",
          content: "ea0e503699f8faae",
        },
        {
          name: `description`,
          content: defaultSEO.description,
        },
        {
          property: `og:locale`,
          content: `en_US`,
        },
        {
          property: `og:url`,
          content: defaultSEO.canonical,
        },
        {
          property: `og:site_name`,
          content: 'Vivid Consulting Group',
        },
        {
          property: `og:title`,
          content: opengraphTitle || defaultSEO.title,
        },
        {
          property: `og:description`,
          content: opengraphDescription || defaultSEO.description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: "summary_large_image",
        },
        {
          name: `twitter:title`,
          content: twitterTitle || defaultSEO.title,
        },
        {
          name: `twitter:description`,
          content: twitterDescription || defaultSEO.description,
        },
      ]
        .concat(imageMeta)
        .concat(articleMeta)
        .concat(extraMeta)}
    />
  );
}

SEO.defaultProps = {
  extraMeta: [],
  description: ``,
  uri: "",
  seo: {},
};
export default SEO;
