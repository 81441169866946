import React from "react";
import AcfComponents from "../acf-layouts/AcfLayoutMapping";

const FlexibleLayout = ({ layout, backDetails }) => {
  if (!layout?.__typename) {
    return (
      <section id={layout?.id} className="page missing">
        <div className="inner">
          Some component is missing, which is not passed as layout. This means something in the
          schema is broken for this component. Check build console errors.<span>🙅‍</span>
        </div>
      </section>
    );
  }

  const type = layout.__typename;
  const ComponentName = AcfComponents[type];

  if (!ComponentName) {
    return (
      <section id={layout?.id} className="page missing">
        <div className="inner">
          The component <strong>"{layout.__typename}"</strong> is missing. <span>🙅‍</span>
        </div>
      </section>
    );
  }

  // If certain pages need extra data, change this variable conditionally. (Example pageContext)
  let extraData = {};

  if (type === "HeroCta") {
    extraData = {
      ...extraData,
      backDetails,
    };
  }

  return <ComponentName {...layout} {...extraData} />;
};

export default FlexibleLayout;
