import loadable from "@loadable/component";

const AcfLayoutMapping = {
  ApproachCards: loadable(() => import("./ApproachCards/ApproachCards")),
  Hero: loadable(() => import("./Hero/Hero")),
  SolutionCards: loadable(() => import("./SolutionCards/SolutionCards")),
  PageHeader: loadable(() => import("./PageHeader/PageHeader")),
  OurPartners: loadable(() => import("./OurPartners/OurPartners")),
  LeadershipCards: loadable(() => import("./LeadershipCards/LeadershipCards")),
  FeaturedLeadership: loadable(() => import("./FeaturedLeadership/FeaturedLeadership")),
  HeroSimple: loadable(() => import("./HeroSimple/HeroSimple")),
  HeroCta: loadable(() => import("./HeroCta/HeroCta")),
  CtaScroll: loadable(() => import("./CtaScroll/CtaScroll")),
  ContactForm: loadable(() => import("./ContactForm/ContactForm")),
  FeaturedList: loadable(() => import("./FeaturedList/FeaturedList")),
  Statement: loadable(() => import("./Statement/Statement")),
  Summary: loadable(() => import("./Summary/Summary")),
  Details: loadable(() => import("./Details/Details")),
  FeaturedGrid: loadable(() => import("./FeaturedIGrid/FeaturedGrid")),
  RelatedItems: loadable(() => import("./RelatedItems/RelatedItems")),
  Quote: loadable(() => import("./Quote/Quote")),
  Overview: loadable(() => import("./Overview/Overview")),
  StackedImages: loadable(() => import("./StackedImages/StackedImages")),
  GridCard: loadable(() => import("./GridCard/GridCard")),
};

export default AcfLayoutMapping;
